<template v-if="userData">
  <v-row class="user-bio-panel">
    <!-- user profile -->
    <v-col
      cols="12"
    >
      <!-- Avatar Card -->
      <v-card class="pt-10 pb-5">
        <v-card-title class="justify-center flex-column">
          <v-avatar
            :color="userData.avatar ? '' : 'primary'"
            :class="userData.avatar ? '' : 'v-avatar-light-bg primary--text'"
            size="120"
            rounded
            class="mb-4"
          >
            <v-img
              v-if="userData.avatar"
              :src="require(`@/assets/images/avatars/${userData.avatar}`)"
            ></v-img>
            <span
              v-else
              class="font-weight-semibold text-5xl"
            >{{ avatarText(newName) }}</span>
          </v-avatar>
          <!-- Display Name -->
          <v-alert
            v-if="!showNameEdit"
            dark
            color="primary"
            @click="valueCaptured = newName; showNameEdit = true"
          >
            <h3><strong>{{ newName }}</strong></h3>
          </v-alert>
          <!-- Edit Display Name (From Click) -->
          <v-row
            v-if="showNameEdit"
          >
            <v-col
              align-self="center"
              sm="9"
            >
              <v-text-field
                v-model="newName"
              >
              </v-text-field>
            </v-col>
            <v-col sm="3">
              <v-btn
                icon
                @click="showNameEdit = false; newName = valueCaptured"
              >
                <v-icon color="error">
                  {{ icons.mdiCloseThick }}
                </v-icon>
              </v-btn>
              <v-btn
                icon
                @click="alterUser({ property: 'display_name', value: `${newName}` }); showNameEdit = false"
              >
                <v-icon color="success">
                  {{ icons.mdiCheckBold }}
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <!-- Add IF Snowflake and Password Graphics -->
          <v-row
            justify="space-around"
            class="mt-3"
          >
            <v-tooltip
              v-if="userData.hasPassword"
              top
            >
              <template v-slot:activator="{ on, attrs }">
                <v-avatar
                  v-bind="attrs"
                  size="30"
                  color="info"
                  class="v-avatar-light-bg primary--text me-3"
                  v-on="on"
                >
                  <v-icon
                    size="18"
                    color="info"
                  >
                    {{ userData.hasPassword ? icons.mdiSnowflake : null }}
                  </v-icon>
                </v-avatar>
              </template>
              <span>{{ userData.hasPassword ? 'User HAS a Snowflake Password' : 'User DOES NOT have a Snowflake Password' }}</span>
            </v-tooltip>
            <v-tooltip
              v-if="userData.appUserId"
              top
            >
              <template v-slot:activator="{ on, attrs }">
                <v-avatar
                  v-bind="attrs"
                  size="30"
                  color="primary"
                  class="v-avatar-light-bg primary--text me-3"
                  v-on="on"
                >
                  <v-icon
                    size="18"
                    color="primary"
                  >
                    {{ userData.appUserId ? icons.mdiKeyVariant : null }}
                  </v-icon>
                </v-avatar>
              </template>
              <span>{{ userData.appUserId ? 'User HAS ' + serverConfig.name + ' Access' : 'User DOES NOT have ' + serverConfig.name + ' Access' }}</span>
            </v-tooltip>
          </v-row>
        </v-card-title>
        <!-- Role and Compute Graphics -->
        <v-row
          class="ma-3"
          justify="space-around"
        >
          <div>
            <v-icon color="info">
              {{ icons.mdiBadgeAccountOutline }}
            </v-icon>
            <span class="info--text caption"><strong>{{ bioItems.filter(f => f.property === 'default_role')[0].value }}</strong></span>
          </div>
          <div>
            <v-icon color="warning">
              {{ icons.mdiCogOutline }}
            </v-icon>
            <span class="warning--text caption"><strong>{{ bioItems.filter(f => f.property === 'default_warehouse')[0].value }}</strong></span>
          </div>
        </v-row>
        <v-card-text>
          <v-divider></v-divider>

          <v-list>
            <v-row
              justify="space-around"
              class="mb-3 my-0"
              no-gutters
            >
              <v-col sm="6">
                <v-list-item
                  dense
                  class="px-0 mb-n2"
                >
                  <span class="font-weight-medium me-2">Status:</span>
                  <span class="text--secondary">
                    <v-chip
                      small
                      label
                      :color="userData.enabled ? 'success' : 'error'"
                      :class="`v-chip-light-bg ${userData.enabled ? 'success' : 'error'}--text font-weight-medium text-capitalize`"
                    >
                      <strong>{{ userData.enabled ? 'ENABLED' : 'DISABLED' }}</strong>
                    </v-chip>
                  </span>
                </v-list-item>
              </v-col>
              <v-col sm="6">
                <v-list-item
                  dense
                  class="px-0 mb-n2"
                >
                  <span class="font-weight-medium me-2">Last Login:</span>
                  <span class="text--secondary">
                    <v-chip
                      small
                      label
                      :color="userData.enabled ? 'success' : 'error'"
                      :class="`v-chip-light-bg ${userData.enabled ? 'success' : 'error'}--text font-weight-medium text-capitalize`"
                    >
                      <strong v-if="userData.lastLogin">{{ userData.lastLogin | moment("from") }}</strong>
                      <strong v-if="!userData.lastLogin">{{ 'Never' }}</strong>
                    </v-chip>
                  </span>
                </v-list-item>
              </v-col>
            </v-row>

            <!-- USER LIST BIO ITEMS -->
            <!-- ------------------- -->
            <v-list-item
              v-for="item in bioItems"
              :key="item.name"
              dense
              class="px-0 mb-n2"
            >
              <v-row no-gutters>
                <v-col
                  align-self="center"
                  sm="3"
                  cols="12"
                >
                  <span class="font-weight-medium me-2">{{ item.name }}</span>
                </v-col>
                <v-col
                  align-self="center"
                  dense
                  :sm="item.canEdit ? '7' : '9'"
                  cols="12"
                >
                  <v-text-field
                    v-if="item.type === 'textbox'"
                    v-model="item.value"
                    dense
                    :flat="!item.canEdit || (item.canEdit && item.disabled)"
                    :solo="!item.canEdit || (item.canEdit && item.disabled)"
                    :disabled="!item.canEdit || (item.canEdit && item.disabled)"
                    :hide-details="!item.canEdit || (item.canEdit && item.disabled)"
                  >
                  </v-text-field>
                  <v-select
                    v-if="item.type === 'selection'"
                    v-model="item.value"
                    :items="item.list"
                    dense
                    flat
                    solo
                    :disabled="item.disabled"
                    :append-icon="item.disabled ? '' : '$dropdown'"
                    hide-details
                  >
                  </v-select>
                </v-col>
                <!-- Cancel Changes Icon -->
                <v-col
                  v-if="item.canEdit && !item.disabled"
                  align-self="center"
                  dense
                  :sm="item.canEdit ? '1' : '0'"
                  cols="12"
                >
                  <v-btn
                    small
                    class="ma-0"
                    icon
                    @click="item.disabled = !item.disabled; item.value = valueCaptured"
                  >
                    <v-icon

                      color="error"
                    >
                      {{ icons.mdiCloseThick }}
                    </v-icon>
                  </v-btn>
                </v-col>
                <!-- Accept Changes Icon -->
                <v-col
                  v-if="item.canEdit && !item.disabled"
                  align-self="center"
                  dense
                  sm="1"
                  cols="12"
                >
                  <v-btn
                    small
                    class="ma-0"
                    icon
                    @click="item.disabled = !item.disabled; showDialog(item)"
                  >
                    <v-icon color="success">
                      {{ icons.mdiCheckBold }}
                    </v-icon>
                  </v-btn>
                </v-col>
                <!-- Edit Changes Icon -->
                <v-col
                  v-if="item.canEdit && item.disabled"
                  align-self="center"
                  dense
                  sm="1"
                  cols="12"
                >
                  <v-btn
                    small
                    class="ma-0"
                    icon
                    @click="item.disabled = !item.disabled; valueCaptured = item.value"
                  >
                    <v-icon>
                      {{ icons.mdiSquareEditOutline }}
                    </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-list-item>
          </v-list>
        </v-card-text>

        <v-card-actions class="justify-center">
          <v-btn
            :disabled="userData.isCurrentUser"
            :color="userData.enabled ? 'error' : 'success'"
            outlined
            @click="userData.enabled ? disableUser() : enableUser()"
          >
            {{ userData.enabled ? 'Disable User' : 'Enable User' }}
          </v-btn>
          <v-btn
            color="warning"
            outlined
            @click="showChangePassword = !showChangePassword"
          >
            {{ userData.hasPassword ? 'Change Password' : 'Create Password' }}
          </v-btn>
        </v-card-actions>
        <v-card
          v-if="showChangePassword"
          dense
          class="ma-5 pb-5"
        >
          <v-card-title dense>
            Change Password
          </v-card-title>
          <v-row

            dense
            class="ml-5 mr-5 mt-5 mb-0"
            justify="space-around"
            label="New Password"
            no-gutters
          >
            <v-col sm="12">
              <v-alert
                v-if="passwordEmpty"
                type="error"
              >
                Password Cannot Be Empty!
              </v-alert>
            </v-col>
            <v-col
              sm="12"
            >
              <v-text-field
                v-model="newPassword"
                outlined
                dense
                :type="isPasswordVisible ? 'text' : 'password'"
                :rules="[required,passwordValidator]"
                :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline:icons.mdiEyeOutline"
                required
                label="Password"
                hide-details="auto"
                class="mb-6"
                @click:append="isPasswordVisible = !isPasswordVisible"
              ></v-text-field>
            </v-col>
            <v-col
              dense
              align-self="center"
              sm="12"
            >
              <v-checkbox
                v-model="requirePasswordChange"
                label="Require Password Change"
              ></v-checkbox>
            </v-col>
            <v-row>
              <v-col
                sm="4"
              >
                <v-btn
                  text
                  color="error"
                  @click="showChangePassword = false"
                >
                  Cancel
                </v-btn>
              </v-col>
              <v-col
                sm="4"
              >
                <v-btn
                  :loading="passwordLoading"
                  class="primary"
                  @click="changePassword()"
                >
                  Set Password
                </v-btn>
              </v-col>
            </v-row>
          </v-row>
        </v-card>
      </v-card>
    </v-col>
    <template>
      <v-dialog
        v-model="dialog.show"
        persistent
        width="500"
      >
        <v-card>
          <v-card-title class="headline">
            {{ dialog.title }}
          </v-card-title>
          <v-card-text>{{ dialog.message }}</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="error"
              outlined
              @click="dialog.show = false; cancelDialog()"
            >
              CANCEL
            </v-btn>
            <v-btn
              color="success"
              @click="dialog.show = false; continueDialog()"
            >
              CONTINUE
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
    <v-snackbar
      v-model="showSnackBarDialog"
      color="primary"
    >
      <span><strong>{{ snackBarMessage }}</strong></span>
      <template #action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="showSnackBarDialog = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-row>
</template>

<script>
import { dbLog } from '@/functions'
import { snowflakeQuery } from '@/snowflake'
import { alterSnowflakeUser } from '@/snowflake/snowflakeAdministration'
import store from '@/store'
import { avatarText } from '@core/utils/filter'
import { passwordValidator, required } from '@core/utils/validation'
// eslint-disable-next-line object-curly-newline
import { mdiBadgeAccountOutline, mdiCheckBold, mdiCheckboxBlankCircle, mdiCloseThick, mdiCogOutline, mdiEyeOffOutline, mdiEyeOutline, mdiKeyVariant, mdiSnowflake, mdiSquareEditOutline } from '@mdi/js'
import serverConfig from '@serverConfig'
// eslint-disable-next-line object-curly-newline
import { computed, inject, onMounted, ref } from '@vue/composition-api'

export default {
  setup() {
    const isPasswordVisible = ref(false)
    const showNameEdit = ref(false)
    const newName = ref(null)
    const showSnackBarDialog = ref(false)
    const snackBarMessage = ref(null)
    const valueCaptured = ref(null)
    const isBioDialogOpen = ref(false)
    const currentItem = ref(null)
    const showChangePassword = ref(false)
    const newPassword = ref(null)
    const requirePasswordChange = ref(true)
    const passwordEmpty = ref(false)
    const passwordLoading = ref(false)

    const dialog = ref({
      show: false,
      title: null,
      message: null,
    })

    const userData = inject('userData')

    onMounted(() => {
      newName.value = userData.value.displayName
    })

    // const allUserRoles = computed(() => {
    //   const snowflakeUserRoles = store.state.snowflakeData.snowflakeUserRoles.filter(f => f.granteeName === )

    // })

    const warehouseSelection = computed(() => userData.value.warehouses)

    const roleSelection = computed(() => store.state.snowflakeData.snowflakeRoles.map(m => m.name))

    const changePassword = async () => {
      passwordLoading.value = true
      if (!newPassword.value || newPassword.value.length < 1) {
        passwordEmpty.value = true

        return
      }
      try {
        const statement = `alter user "${userData.value.loginName}" set PASSWORD = "${newPassword.value}" must_change_password = ${requirePasswordChange.value}`
        const response = await snowflakeQuery(statement)
        if (response.rows[0].status.includes('success')) {
          snackBarMessage.value = 'User Password Changed Successfully'
          showSnackBarDialog.value = true
          await dbLog({
            alteredSnowflakeUser: userData.value.loginName,
            alteredProperty: 'change_password',
            alteredValue: 'new_password',
            logType: 'alterSnowflakeUser',
            timestamp: new Date(),
          })
        }
        passwordLoading.value = false
        showChangePassword.value = false
      } catch (err) {
        passwordLoading.value = false
        store.dispatch('systemMessages', `<strong>Could Not Change Password: ${err}</strong>`)
      }
    }

    const bioItems = ref([
      {
        name: 'Username',
        type: 'textbox',
        value: userData.value.loginName,
        canEdit: false,
      },
      {
        name: 'Email',
        type: 'textbox',
        value: userData.value.email,
        canEdit: true,
        property: 'email',
        disabled: true,
        loading: false,
      },

      {
        name: 'Owner',
        type: 'textbox',
        value: userData.value.owner,
        canEdit: false,
      },
      {
        name: 'Default Role',
        type: 'selection',
        value: userData.value.defaultRole,
        canEdit: true,
        property: 'default_role',
        list: roleSelection.value,
        disabled: true,
        loading: false,
      },
      {
        name: 'Warehouse',
        type: 'selection',
        value: userData.value.defaultWarehouse,
        canEdit: true,
        property: 'default_warehouse',
        list: warehouseSelection.value,
        disabled: true,
        loading: false,
      },
      {
        name: 'Namespace',
        type: 'textbox',
        value: userData.value.defaultNamespace,
        canEdit: true,
        property: 'default_namespace',
        disabled: true,
        loading: false,
      },
    ])

    const alterUser = async item => {
      const bioIndex = bioItems.value.findIndex(i => i.property === item.property)
      if (bioIndex > -1) bioItems.value[bioIndex].loading = true
      try {
        let itemValue = item.value // Set Needed Values to Strings
        if (['default_role', 'default_warehouse', 'default_namespace', 'display_name'].includes(item.property)) {
          itemValue = `"${item.value}"`
        }
        console.log({ userData: userData.value.loginName, item: item.property, itemValue })
        const alterStatement = await alterSnowflakeUser(userData.value.loginName, item.property, itemValue)
        if (alterStatement.rows[0].status.includes('success')) {
          snackBarMessage.value = 'User Updated Successfully'
          showSnackBarDialog.value = true
        }
        if (bioIndex > -1) bioItems.value[bioIndex].loading = false

        // Set Execution Role If Currently Missing
        console.log('item.property', item.property)
        console.log('localStorage', localStorage.getItem('executionRole'))
        if (item.property === 'default_role' && !store.state.executionRole) {
          store.commit('setExecutionRole', itemValue.replace(/"/g, ''))
        }
      } catch (err) {
        if (bioIndex > -1) bioItems.value[bioIndex].loading = false

        // Set Value Back To Default
        if (bioIndex > -1) bioItems.value[bioIndex].value = valueCaptured.value

        store.dispatch('systemMessages', `<strong>${err}</strong>`)
      }
    }

    const cancelDialog = () => {
      const bioIndex = bioItems.value.findIndex(i => i.property === currentItem.value.property)
      bioItems.value[bioIndex].value = valueCaptured.value
      bioItems.value[bioIndex].disabled = true
    }

    const grantRole = async () => {
      try {
        const query = `grant role "${currentItem.value.value}" to user "${userData.value.loginName}";`
        console.log('Attempting to Alter User')
        const roleResponse = await snowflakeQuery(query)
        console.log('roleResponse', roleResponse)
        if (roleResponse.rows[0].status.includes('success')) {
          snackBarMessage.value = 'User Role Granted Successfully'
          showSnackBarDialog.value = true
          alterUser(currentItem.value)
          store.commit('snowflakeData/addSnowflakeUserRoles', { granteeName: userData.value.loginName, role: currentItem.value.value })
        } else {
          const errorMessage = roleResponse.rows[0].status
          throw errorMessage
        }
      } catch (err) {
        cancelDialog()
        store.dispatch('systemMessages', `<strong>Failed To Grant Role To User: ${err}</strong>`)
      }
    }

    const continueDialog = () => {
      if (currentItem.value.property === 'default_role') {
        grantRole()
      } else {
        alterUser(currentItem.value)
      }
    }

    const disableUser = () => {
      dialog.value.title = `Disable User: ${userData.value.loginName}?`
      dialog.value.message = `Are you sure that you want to disable Snowflake access for the user: ${userData.value.loginName}`
      dialog.value.show = true
      currentItem.value = { property: 'disabled', value: true }
    }

    const enableUser = () => {
      dialog.value.title = `Enable User: ${userData.value.loginName}?`
      dialog.value.message = `Are you sure that you want to enable Snowflake access for the user: ${userData.value.loginName}`
      dialog.value.show = true
      currentItem.value = { property: 'disabled', value: false }
    }

    const showDialog = async item => {
      currentItem.value = item
      if (item.property === 'email' && userData.value.email.includes('@')) {
        dialog.value.show = true
        dialog.value.title = 'Use Caution Changing Emails'
        // eslint-disable-next-line operator-linebreak
        dialog.value.message = `Be careful when changing email addresses between Snowflake Users. ${serverConfig.name} uses emails to identify Snowflake users with their ${serverConfig.name} accounts. Make sure the emails match.`
      } else if (item.property === 'default_role') {
        if (!userData.value.roles.includes(item.value)) {
          dialog.value.title = `${item.value} role not granted to user.`
          dialog.value.message = `The user does not have access to the ${item.value} Role. Would you like to attempt to grant this role to the user?`
          dialog.value.show = true
        } else {
          alterUser(item)
        }
      } else {
        alterUser(item)
      }
    }

    return {
      userData,
      passwordLoading,
      passwordValidator,
      required,
      isPasswordVisible,
      changePassword,
      passwordEmpty,
      newPassword,
      requirePasswordChange,
      showChangePassword,
      disableUser,
      enableUser,
      serverConfig,
      cancelDialog,
      continueDialog,
      showNameEdit,
      newName,
      showSnackBarDialog,
      snackBarMessage,
      alterUser,
      bioItems,
      showDialog,
      dialog,
      valueCaptured,
      avatarText,
      warehouseSelection,
      roleSelection,
      isBioDialogOpen,
      icons: {
        mdiKeyVariant,
        mdiSnowflake,
        mdiCheckBold,
        mdiSquareEditOutline,
        mdiBadgeAccountOutline,
        mdiCogOutline,
        mdiCheckboxBlankCircle,
        mdiCloseThick,
        mdiEyeOffOutline,
        mdiEyeOutline,
      },
    }
  },
}
</script>
