<template>
  <v-card>
    <v-card-text>
      <v-row>
        <v-col
          cols="12"
          sm="9"
          class="executions-col"
        >
          <v-card-title class="px-0 pt-0">
            Snowflake Consumption (Last 7 Days)
          </v-card-title>
          <vue-apex-charts
            id="executions-chart"
            height="320"
            :options="chartOptions"
            :series="chartData"
          ></vue-apex-charts>
        </v-col>

        <v-col
          cols="12"
          sm="3"
        >
          <v-card elevation="0">
            <!-- Title/Header -->
            <v-card-title class="align-start pt-0 flex-nowrap">
              <div>
                <p class="mb-0 font-weight-bold text-2xl">
                  {{ executionTotals.totalCost }}
                </p>
                <small>{{ dateRangeFilterSelection.name }}</small>
              </div>
              <v-spacer></v-spacer>
              <v-btn
                icon
                small
                class="me-n7 mt-n1"
              >
                <v-icon size="22">
                  {{ icons.mdiDotsVertical }}
                </v-icon>
              </v-btn>
            </v-card-title>

            <v-card-text class="pb-0 pt-5">
              <!-- List -->
              <v-list
                two-line
                subheader
              >
                <!-- List Item: Profit -->
                <v-list-item class="pa-0">
                  <v-list-item-avatar
                    class="v-avatar-light-bg success--text justify-center"
                    size="40"
                    rounded
                  >
                    <v-avatar
                      size="44"
                      color="info"
                      rounded
                      class="elevation-1"
                    >
                      <v-icon
                        size="30"
                        color="white"
                      >
                        {{ icons.mdiCurrencyUsd }}
                      </v-icon>
                    </v-avatar>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title class="font-weight-semibold">
                      {{ executionTotals.totalCredits }}
                    </v-list-item-title>
                    <small>Credits Consumed</small>
                  </v-list-item-content>
                </v-list-item>
                <!-- List Item: Income -->
                <v-list-item class="pa-0">
                  <v-list-item-avatar
                    class="v-avatar-light-bg primary--text justify-center"
                    size="40"
                    rounded
                  >
                    <v-avatar
                      size="44"
                      color="warning"
                      rounded
                      class="elevation-1"
                    >
                      <v-icon
                        size="30"
                        color="white"
                      >
                        {{ icons.mdiClockFast }}
                      </v-icon>
                    </v-avatar>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title class="font-weight-semibold">
                      {{ executionTotals.totalDuration }}
                    </v-list-item-title>
                    <small>Consumption Duration (Minutes)</small>
                  </v-list-item-content>
                </v-list-item>
                <!-- List Item: Expense -->
                <v-list-item class="pa-0">
                  <v-list-item-avatar
                    color="primary"
                    class="v-avatar-light-bg secondary--text justify-center"
                    size="40"
                    rounded
                  >
                    <v-avatar
                      size="44"
                      color="success"
                      rounded
                      class="elevation-1"
                    >
                      <v-icon
                        size="30"
                        color="white"
                      >
                        {{ icons.mdiCogClockwise }}
                      </v-icon>
                    </v-avatar>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title class="font-weight-semibold">
                      {{ executionTotals.totalExecutions }}
                    </v-list-item-title>
                    <small>Total Executions</small>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
              <!-- Action Button -->
              <v-btn
                block
                color="primary"
              >
                View Report
              </v-btn>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { colorLight, formatCurrency, formatNumbers } from '@/functions'
import { getDateRange } from '@/functions/dates'
import store from '@/store'
import { getVuetify } from '@core/utils'
import { kFormatter } from '@core/utils/filter'
// eslint-disable-next-line object-curly-newline
import { mdiClockFast, mdiCogClockwise, mdiCurrencyUsd, mdiDotsVertical } from '@mdi/js'
import { computed, inject } from '@vue/composition-api'
import _ from 'lodash'
import moment from 'moment-timezone'
import VueApexCharts from 'vue-apexcharts'

export default {
  components: {
    VueApexCharts,
  },
  setup(props, context) {
    const dateRangeFilterSelection = computed(() => store.getters.dateRangeFilter)
    const snowflakeContractDetails = computed(() => store.state.snowflakeData.snowflakeContractDetails)

    // const getUserConsumption = computed(() => store.getters[`snowflakeData/snowflakeUserConsumption${dateRangeFilterSelection.value.objectType}`])

    // Get Filter Range Days
    const dateRangeFilterDays = computed(() => store.getters.dateRangeFilterDays)
    const userId = inject('userId')

    // Get Execution and Consumption Data and Group by Date
    const executions = computed(() => {
      const getExecutionData = store.state.snowflakeData.snowflakeExecutionSummary.filter(f => f.user === userId.value && f.executionType === 'last7DaysExpanded')
      let executionData = []
      getExecutionData.forEach(warehouse => {
        executionData = [...executionData, ...warehouse.timestamps]
      })
      executionData = _(executionData)
        .groupBy('t')
        .map((m, timestamp) => ({
          timestamp,
          executions: _.sumBy(m, 'ex'),
          errors: _.sumBy(m, 'ee') || 0,
          duration: _.sumBy(m, 'du'),
        }))
        .value()

      // Get All Averages
      const avgExecutionData = store.state.snowflakeData.snowflakeExecutionSummary.filter(f => f.executionType === 'last7DaysExpanded')
      let averageExecutions = []
      avgExecutionData.forEach(warehouse => {
        averageExecutions = [...averageExecutions, ...warehouse.timestamps]
      })
      averageExecutions = _(averageExecutions)
        .groupBy('t')
        .map((m, timestamp) => ({
          timestamp,
          avgDuration: _.meanBy(m, 'du'),
        }))
        .value()

      // Align Days Correctly
      const alignedExecutions = []
      const days = getDateRange(moment().subtract(6, 'days').toDate(), moment().toDate(), 'day').map(m => moment(m).format('YYYYMMDD'))
      days.forEach(day => {
        const executionRecord = executionData.filter(f => moment(Number(f.timestamp) * 1000).format('YYYYMMDD') === day)[0]
        if (executionRecord) {
          alignedExecutions.push(executionRecord)
        } else {
          alignedExecutions.push({ executions: 0, errors: 0, duration: 0 })
        }
      })
      days.forEach((day, i) => {
        const avgExecutionRecord = averageExecutions.filter(f => moment(Number(f.timestamp) * 1000).format('YYYYMMDD') === day)[0]
        if (avgExecutionRecord) {
          alignedExecutions[i] = { ...alignedExecutions[i], avgDuration: avgExecutionRecord.avgDuration }
        } else {
          alignedExecutions[i] = { ...alignedExecutions[i], avgDuration: 0 }
        }
      })

      // Build Graph Axis
      const successfulExecutions = alignedExecutions.map(m => m.executions)
      const failedExecutions = alignedExecutions.map(m => m.errors)
      const duration = alignedExecutions.map(m => m.duration)
      const avgDuration = alignedExecutions.map(m => m.avgDuration)
      const dates = days.map(m => moment(m, 'YYYYMMDD').format())

      return {
        successfulExecutions,
        failedExecutions,
        duration,
        avgDuration,
        dates,
      }
    })

    const executionTotals = computed(() => {
      const getTotalsByFilter = store.getters['snowflakeData/snowflakeExecutionsByFilter'].filter(f => f.user === userId.value)
      const totalExecutions = _.sumBy(getTotalsByFilter, 'ex') || 0
      const totalDuration = _.sumBy(getTotalsByFilter, 'du') || 0
      const totalCredits = _.sumBy(getTotalsByFilter, 'wc') || 0
      const totalCost = totalCredits * snowflakeContractDetails.value.creditCost || 0

      return {
        totalCredits: formatNumbers(totalCredits),
        totalCost: formatCurrency(totalCost),
        totalExecutions: formatNumbers(totalExecutions),
        totalDuration: formatNumbers(totalDuration / 1000 / 60),
      }
    })

    const $vuetify = getVuetify()

    const chartData = computed(() => [
      {
        name: 'Successful Executions',
        type: 'column',

        data: executions.value.successfulExecutions,
      },
      {
        name: 'Failed Executions',
        type: 'column',
        data: executions.value.failedExecutions,
      },
      {
        name: 'Execution Time',
        type: 'line',
        data: executions.value.duration,
      },
      {
        name: 'Avg Execution Time',
        type: 'area',
        data: executions.value.avgDuration, // REplace
      },
    ])

    const chartOptions = computed(() => ({
      colors: [colorLight($vuetify.theme.currentTheme.success), colorLight($vuetify.theme.currentTheme.error), $vuetify.theme.currentTheme.warning, colorLight($vuetify.theme.currentTheme.primary, 0.1)],
      chart: {
        height: 350,
        type: 'line',
        stacked: true,
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          borderRadius: 10,
          columnWidth: '60%',
          startingShape: 'rounded',
          endingShape: 'rounded',
        },
      },
      dataLabels: {
        enabled: true,
        enabledOnSeries: [0, 1],
        formatter: val => formatNumbers(val),
        style: {
          fontSize: '12px',
          colors: [$vuetify.theme.currentTheme.success, $vuetify.theme.currentTheme.error],
        },
      },
      stroke: {
        colors: ['white', 'white', $vuetify.theme.currentTheme.warning, $vuetify.theme.currentTheme.primary],
        width: [1, 1, 4, 4],
      },
      xaxis: {
        categories: executions.value.dates,
        type: 'datetime',
      },
      yaxis: [
        {
          seriesName: 'Successful Executions',
          axisTicks: {
            show: true,
          },
          axisBorder: {
            show: true,
            color: $vuetify.theme.currentTheme.success,
          },
          labels: {
            formatter: value => kFormatter(value, 0),
            style: {
              colors: $vuetify.theme.currentTheme.success,
            },
          },
          title: {
            text: 'Executions',
            style: {
              color: $vuetify.theme.currentTheme.success,
            },
          },
          tooltip: {
            enabled: false,
          },
        },
        {
          seriesName: 'Successful Executions',
          show: false,
          labels: {
            formatter: value => kFormatter(value, 0),
          },
          title: {
            text: 'Failed Executions',
            style: {
              color: '#00E396',
            },
          },
        },
        {
          seriesName: 'Execution Time',
          opposite: true,
          axisTicks: {
            show: true,
          },
          axisBorder: {
            show: true,
            color: $vuetify.theme.currentTheme.warning,
          },
          labels: {
            formatter: value =>
              // eslint-disable-next-line implicit-arrow-linebreak
              context.root.$moment.duration(value, 'milliseconds').humanize({
                s: 60,
                m: 60,
                h: 24,
                d: 7,
                w: 4,
                M: 12,
              }),
            style: {
              colors: $vuetify.theme.currentTheme.warning,
            },
          },
          title: {
            text: 'Execution Time',
            style: {
              color: $vuetify.theme.currentTheme.warning,
            },
          },
        },
        {
          seriesName: 'Execution Time',
          show: false,
          axisTicks: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
          labels: {
            formatter: value =>
              // eslint-disable-next-line implicit-arrow-linebreak
              context.root.$moment.duration(value, 'milliseconds').humanize({
                s: 60,
                m: 60,
                h: 24,
                d: 7,
                w: 4,
                M: 12,
              }),
            style: {
              colors: '#FEB019',
            },
          },
        },
      ],
      tooltip: {
        fixed: {
          enabled: true,
          position: 'bottomRight', // topRight, topLeft, bottomRight, bottomLeft
          offsetY: 0,
          offsetX: 150,
        },
      },
      legend: {
        show: false,
        horizontalAlign: 'left',
        offsetX: 40,
      },
      responsive: [
        {
          breakpoint: 1650,
          options: {
            plotOptions: {
              bar: {
                borderRadius: 10,
                columnWidth: '45%',
              },
            },
          },
        },
      ],
    }))

    return {
      dateRangeFilterDays,
      dateRangeFilterSelection,
      executions,
      executionTotals,
      chartOptions,
      chartData,

      icons: {
        mdiDotsVertical,
        mdiCurrencyUsd,
        mdiCogClockwise,
        mdiClockFast,
      },
    }
  },
}
</script>

<style lang="scss">
@import '~@core/preset/preset/mixins.scss';
@import '~vuetify/src/styles/styles.sass';
@media #{map-get($display-breakpoints, 'sm-and-up')} {
  .executions-chart-col {
    @include ltr() {
      border-right: thin solid;
    }
    @include rtl() {
      border-left: thin solid;
    }
  }
}
@include theme--child(executions-chart-col) using ($material) {
  @media #{map-get($display-breakpoints, 'sm-and-up')} {
    border-color: map-get($material, 'dividers');
  }
}
</style>
