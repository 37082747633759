<template>
  <v-select
    v-model="dateRangeSelection"
    item-color="primary"
    :items="dateSelectionOptions"
    dense
    :prepend-inner-icon="icons.mdiFilterVariant"
    outlined
    filled
    rounded
    hide-details
    :background-color="backgroundColor"
    @change="changeDateFilter"
  >
  </v-select>
</template>

<script>
import { colorLight } from '@/functions'
import store from '@/store'
import { getVuetify } from '@core/utils'
import { mdiFilterVariant } from '@mdi/js'
import { computed, ref } from '@vue/composition-api'

export default {
  props: {
    color: {
      type: String,
      default: () => 'white',
    },
  },
  setup(props) {
    const $vuetify = getVuetify()
    const dateSelectionOptions = computed(() => store.state.dateRangeFilter.map(m => m.name))
    const dateRangeFilter = computed(() => store.state.dateRangeFilter.filter(f => f.active)[0])
    const dateRangeSelection = ref(dateRangeFilter.value.name)
    const changeDateFilter = value => {
      store.commit('setDateRangeFilter', value)
    }

    const backgroundColor = computed(() => {
      if (['primary'].includes(props.color)) {
        return colorLight($vuetify.theme.currentTheme.primary)
      }

      return ''
    })

    return {
      backgroundColor,
      dateSelectionOptions,
      dateRangeSelection,
      changeDateFilter,
      icons: {
        mdiFilterVariant,
      },
    }
  },
}
</script>
